<template>
  <div class="">
    <v-menu
      min-width="280"
      open-on-hover
      :close-on-content-click="false"
      allow-overflow
      close-delay="60"
      h-100
      ma-0
      pa-0
      :content-class="
        $vuetify.rtl ? 'aside-shadow-rtl' : 'aside-shadow overflow-auto'
      "
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="w-100" v-bind="attrs" v-on="on">
          <inline-svg
            class="menu-svg"
            :class="{
              'custom-opacity':
                item.name !== $store.getters.getPreviousSelectedMenu,
            }"
            :src="item.svg"
          />
        </div>
      </template>
      <v-card class="elevation-0 h-100 poppins">
        <v-card-text
          style="color: black; font-weight: bold; line-height: 1.4"
          class="h3 uppercase pt-8 pr-0 text-black text-bold mb-0"
          :class="{ 'text-right pr-2': $vuetify.rtl }"
        >
          {{ $t(item.title) }}
        </v-card-text>

        <v-card-text class="ma-0 pa-0 poppins">
          <v-list v-if="item.type === 'default'">
            <template v-for="(val, index) in item.entities">
              <v-list-item :key="index" v-if="!val.hasOwnProperty('items')">
                <div>
                  <router-link :to="{ name: val.name }">
                    <span
                      class="ma-0 pa-0 font-size-h5 font-weight-bolder text-dark-50 text-hover-dark uppercase poppins"
                      :class="{ 'text-right pr-2': $vuetify.rtl }"
                    >
                      {{ $t(val.title) }}
                    </span>
                  </router-link>
                </div>
              </v-list-item>

              <v-list-group :key="index" v-else no-action class="px-0">
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="$t(val.title)"
                      class="font-size-h5 font-weight-bolder text-dark-50 uppercase poppins"
                      :class="{ 'text-right pr-2': $vuetify.rtl }"
                    />
                  </v-list-item-content>
                </template>

                <v-list-item
                  v-for="child in val.items"
                  :key="child.title"
                  :to="{ name: child.name }"
                  exact
                  class="cursor-pointer bg-hover-light-info pl-8"
                  :class="{ 'bg-light-info': activeSubmenu === child.name }"
                  @click="
                    () => {
                      $router.push({ name: child.name });
                      activeSubmenu = child.name;
                    }
                  "
                >
                  <span
                    class="font-size-lg font-weight-medium text-dark-50 uppercase poppins"
                    :class="{
                      'text-right pr-2': $vuetify.rtl,
                      'text-left pl-2': !$vuetify.rtl,
                    }"
                  >
                    {{ $t(child.title) }}
                  </span>
                </v-list-item>
              </v-list-group>
            </template>
          </v-list>

          <v-list v-else>
            <template v-for="group in item.entities">
              <v-list-group v-if="group?.items" :key="group.title" no-action>
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="$t(group.title)"
                      class="font-size-h5 font-weight-bolder text-dark-50 uppercase poppins"
                      :class="{ 'text-right': $vuetify.rtl }"
                    />
                  </v-list-item-content>
                </template>

                <template v-if="group?.items">
                  <v-list-item
                    v-for="child in group.items"
                    :key="child.title"
                    :to="{ name: child.name }"
                    exact
                    class="cursor-pointer bg-hover-light-info"
                    :class="{
                      'bg-light-info': activeSubmenu === child.name,
                      'pr-4': $vuetify.rtl,
                      'pl-4': !$vuetify.rtl,
                    }"
                    @click="
                      () => {
                        $router.push({ name: child.name });
                        activeSubmenu = child.name;
                      }
                    "
                  >
                    <span
                      class="font-size-lg font-weight-medium text-dark-50 uppercase poppins"
                      :class="{
                        'text-right pr-2': $vuetify.rtl,
                        'text-left pl-2': !$vuetify.rtl,
                      }"
                    >
                      {{ $t(child.title) }}
                    </span>
                  </v-list-item>
                </template>
              </v-list-group>
              <v-list-item
                v-else
                :key="group.title"
                :to="{ name: group.name }"
                exact
                class="cursor-pointer bg-hover-light-info"
                :class="{ 'bg-light-info': activeSubmenu === group.name }"
                @click="
                  () => {
                    $router.push({ name: group.name });
                    activeSubmenu = group.name;
                  }
                "
              >
                <v-list-item-content>
                  <v-list-item-title
                    v-text="$t(group.title)"
                    class="font-size-h5 font-weight-bolder text-dark-50 uppercase poppins"
                    :class="{ 'text-right': $vuetify.rtl }"
                  />
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "SidebarMain",
  props: ["item"],
  data: () => ({
    activeSubmenu: null,
  }),
};
</script>

<style scoped>
.aside-shadow {
  position: fixed !important;
  top: 0px !important;
  height: 100vh !important;
  left: 70px !important;
  overflow-y: hidden;
  width: 260px;
  z-index: 6;
  /* background-color: transparent; */
  box-shadow: 8px 0 25px -8px rgba(138, 0, 138, 0.4),
    -12px 0 8px -4px rgba(255, 255, 255, 0);
  -webkit-box-shadow: 8px 0 25px -8px rgba(138, 0, 138, 0.4),
    -12px 0 8px -4px rgba(255, 255, 255, 0);
  -moz-box-shadow: 8px 0 25px -8px rgba(138, 0, 138, 0.4),
    -12px 0 8px -4px rgba(255, 255, 255, 0);
}
.aside-shadow-rtl {
  position: fixed !important;
  top: 0px !important;
  height: 100vh !important;
  right: 70px !important;
  overflow-y: hidden;
  width: 260px;
  z-index: 6;
  box-shadow: -2px 0 25px 2px rgba(138, 0, 138, 0.4),
    12px 0 8px 4px rgba(255, 255, 255, 0);
  -webkit-box-shadow: -2px 0 25px 2px rgba(138, 0, 138, 0.4),
    12px 0 8px 4px rgba(255, 255, 255, 0);
  -moz-box-shadow: -2px 0 25px 2px rgba(138, 0, 138, 0.4),
    12px 0 8px 4px rgba(255, 255, 255, 0);
}

.custom-opacity {
  filter: grayscale(100%) opacity(35%);
}
svg {
  width: 100%;
}
.menu-svg {
  z-index: 12;
  width: 28px;
  padding: 13px 0;
  height: auto;
}
.menu-svg:hover,
.menu-svg[aria-expanded="true"] {
  cursor: pointer;
  filter: grayscale(0%) opacity(100%);
}
</style>
